<template>
  <div class="card p-3" :class="{ 'border-danger': danger }">
    <div class="card-body">
      <a-list item-layout="horizontal">
        <a-list-item>
          <a-button
            slot="actions"
            :type="danger ? 'danger' : 'primary'"
            size="large"
            @click="() => $emit('click')">
            {{ action }}
          </a-button>
          <a-list-item-meta>
            <h4 slot="title">
              {{ title }}
            </h4>
            <div slot="description" class="pt-2 text-secondary">
              {{ info }}
            </div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JCardAction',
  props: [
    'title',
    'info',
    'action',
    'danger',
  ],
}
</script>

<template>
  <a-form-model-item :prop="prop" :colon="false">
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{$t(prop)}}</span>
    <a-select v-model="selected" v-bind="$attrs" size="large">
      <a-select-option
        v-for="key in values"
        :key="key" :value="key">
        {{ $t(`${root}.${key}`) }}
      </a-select-option>
    </a-select>
  </a-form-model-item>
</template>

<script>
export default {
  name: 'JEnumSelect',
  props: [
    'value',
    'prop',
    'name',
  ],
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      },
    },
    root() {
      return this.name ? this.name : `enum.${this.prop}`
    },
    values() {
      return Object.keys(this.$t(this.root))
    },
  },
}
</script>

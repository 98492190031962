<template>
  <div class="main">
    <div>
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large"
        @click="$router.go(-1)">
        {{ $t('back') }} {{ $t('to') }} {{ $t(`msg.${name}.name`) }}
      </a-button>
      <h2>{{ $t(`msg.abm.action.${suffix}`) }} {{ $t(name) }}</h2>
      <p class="my-4">{{ $t(`msg.${name}.subtitle`) }}</p>
      <!-- Form -->
      <div class="card p-3">
        <div class="card-body">
          <div class="row">
            <slot name="form" v-bind:item="item" />
            <div class="col-xl-5 col-lg-12" />
            <div class="col-xl-7 col-lg-12">
              <a-button
                size="large"
                type="primary"
                class="mr-2"
                @click="onSubmit">
                {{ $t(`msg.abm.action.${suffix}`) }} {{ $t(name) }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Delete -->
      <j-card-action
        :title="`${$t('msg.abm.delete.title')} ${$t(name)} `"
        :action="`${$t('msg.abm.delete.title')} ${$t(name)} `"
        :info="$t('msg.abm.delete.subtitle')"
        :danger="true"
        @click="onDelete"
        v-if="updating && features.delete && $auth.granted(acl[`${name}s`].delete)"/>
    </div>
  </div>
</template>
<script>
import acl from '@/auth/acl'
import cloneDeep from 'lodash/cloneDeep'
import { Modal } from 'ant-design-vue'
import JCardAction from '@/views/shared/cards/action'
export default {
  name: 'JAbm',
  props: [
    'name',
    'features',
    'suppliers',
  ],
  components: {
    JCardAction,
  },
  data: function () {
    return {
      acl,
      item: this.suppliers.payload(),
    }
  },
  mounted() {
    if (this.updating) {
      this.suppliers.fetchOne(this.id).then(r => (this.item = r.data))
    }
  },
  computed: {
    id() {
      return this.$route.params[this.name]
    },
    suffix() {
      return this.$route.path.split('/').pop()
    },
    creating() {
      return this.suffix === 'create'
    },
    updating() {
      return this.suffix === 'update'
    },
  },
  methods: {
    onDelete() {
      const self = this
      Modal.confirm({
        title: this.$t('msg.abm.delete.alert'),
        content: this.$t('msg.abm.delete.subtitle'),
        okType: 'danger',
        okText: this.$t('yes'),
        cancelText: this.$t('no'),
        onOk() {
          const copy = cloneDeep(self.item)
          self.$emit('delete', copy)
        },
        onCancel() {},
      })
    },
    onSubmit() {
      const copy = cloneDeep(this.item)
      this.$emit(this.suffix, copy)
    },
  },
}
</script>

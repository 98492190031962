<template>
  <j-abm
    name="rate"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: true,
    }"
    @create="onCreate"
    @update="onUpdate"
    @delete="onDelete">
    <template #form="{item}">
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('rate') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
            ref="form"
            :model="item"
            :rules="rules">
          <j-enum-select
            prop="method"
            v-model="item.method" />
          <j-input
            prop="code"
            v-model="item.code"
            icon="number" />
          <j-input
            prop="value"
            v-model="item.value"
            icon="dollar" />
          <j-enum-select
            prop="currency"
            v-model="item.currency" />
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import JAbm from '@/views/shared/abm/update'
import JInput from '@/views/shared/forms/input'
import JEnumSelect from '@/views/shared/forms/enum-select'
export default {
  components: {
    JAbm,
    JInput,
    JEnumSelect,
  },
  data: function () {
    return {
      f,
      rules: {
        method: [v.required],
        currency: [v.required],
        code: [v.required, v.number],
        value: [v.required, v.number],
      },
    }
  },
  computed: {
    account() {
      return this.$route.params.account
    },
    suppliers() {
      return {
        fetchOne: id => lms.account.fetchRate(this.account, id),
        payload: () => {
          return {
            method: 'EXPRESS',
            currency: 'ARS',
          }
        },
      }
    },
  },
  methods: {
    onCreate(rate) {
      this.onSubmit(rate, rate => lms.account.createRate(this.account, rate))
    },
    onUpdate(rate) {
      this.onSubmit(rate, rate => lms.account.updateRate(this.account, rate))
    },
    onDelete(rate) {
      this.onSubmit(rate, rate => lms.account.removeRate(this.account, rate))
    },
    onSubmit(rate, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(rate).then(_ => this.$router.push(`/accounts/${this.account}/rates`))
        }
      })
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"rate","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: true,
  }},on:{"create":_vm.onCreate,"update":_vm.onUpdate,"delete":_vm.onDelete},scopedSlots:_vm._u([{key:"form",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"col-xl-5 col-lg-12 mb-2"},[_c('h5',[_vm._v(_vm._s(_vm.$t('rate')))])]),_c('div',{staticClass:"col-xl-7 col-lg-12"},[_c('a-form-model',{ref:"form",attrs:{"model":item,"rules":_vm.rules}},[_c('j-enum-select',{attrs:{"prop":"method"},model:{value:(item.method),callback:function ($$v) {_vm.$set(item, "method", $$v)},expression:"item.method"}}),_c('j-input',{attrs:{"prop":"code","icon":"number"},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}}),_c('j-input',{attrs:{"prop":"value","icon":"dollar"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('j-enum-select',{attrs:{"prop":"currency"},model:{value:(item.currency),callback:function ($$v) {_vm.$set(item, "currency", $$v)},expression:"item.currency"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }